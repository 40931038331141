import erc721Abi from '../config/abi/ERC721.json'
import StakeBakedAbi from '../config/abi/StakeBakedMultiple.json'
import StakedToken from '../config/abi/StakedToken.json'
import {ethers} from "ethers"
import {simpleRpcProvider} from "@/utils/web3"


export const getPotatoesContract = () => {
  return getContract(erc721Abi, process.env.VUE_APP_STAKED_TOKEN_ADDRESS)
}

export const getPuppetsContract = () => {
  return getContract(erc721Abi, process.env.VUE_APP_STAKED_TOKEN_PUPPETS_ADDRESS)
}

export const getStakingContract = () => {
  return getContract(StakeBakedAbi, process.env.VUE_APP_STAKE_CONTACT_ADDRESS)
}

export const getStakedTokenContract = (address) => {
  return getContract(StakedToken, address)
}

const getContract = (abi, address, signer = null) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}
